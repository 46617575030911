/**
 * List all tags used by videos
 */
import React from 'react'
import kebabCase from 'lodash/kebabCase'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import styled from 'styled-components'

// TODO: unify with the other TagsList component used by articles, kb...
const TagsList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    display: inline-block;
    margin-right: 10px;
    line-height: 1.5;

    a {
      color: ${props => props.theme.linkColor};
    }
  }
`

export default function VideoTagsPage(
  {
    data: {
      allEgghead: { group },
      site: {
        siteMetadata: { title },
      },
    },
  },
  location
) {
  return (
    <Layout location={location} title={title}>
      <SEO
        title="Video Tags"
        keywords={[`videos`, `python`, `javascript`, `react`]}
      />
      <h1>Browse Videos by Tag</h1>
      <TagsList>
        {group.map(tag => (
          <li key={tag.fieldValue}>
            <Link to={`/videos/tags/${kebabCase(tag.fieldValue)}/`}>
              #{tag.fieldValue}
            </Link>
          </li>
        ))}
      </TagsList>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allEgghead {
      group(field: tags) {
        fieldValue
        totalCount
      }
    }
  }
`
